import { ExternalSystemsState } from './external-systems/external-systems.state';
import { LensState } from './lens/lens.state';
import { AuthState } from './auth/auth.state';
import { CreateWorkState } from './create-work/create-work.state';
import { GlobalFiltersState } from './global-filters/global-filters.state';
import { provideStore, withNgxsDevelopmentOptions } from '@ngxs/store';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { withNgxsLoggerPlugin } from '@ngxs/logger-plugin';
import { withNgxsRouterPlugin } from '@ngxs/router-plugin';
import { withNgxsFormPlugin } from '@ngxs/form-plugin';

import { CoreState } from './core.state';
import { AlarmsState } from './alarms/alarms.state';
import { UsersState } from './users/users.state';
import { environment } from '@vfi-ui/environments/environment';
import { CustomersState } from './customers/customers.state';
import { WorkTicketsState } from './work-tickets/work-tickets.state';
import { TeamsState } from './teams/teams.state';

export const STATE_PROVIDER = provideStore(
  [
    AlarmsState,
    CoreState,
    GlobalFiltersState,
    CreateWorkState,
    UsersState,
    ExternalSystemsState,
    AuthState,
    CustomersState,
    WorkTicketsState,
    LensState,
    TeamsState,
  ],
  !environment.production &&
    withNgxsDevelopmentOptions({ warnOnUnhandledActions: true }),
  withNgxsLoggerPlugin({ disabled: environment.disableLogger }),
  withNgxsStoragePlugin({ keys: ['core'] }),
  withNgxsRouterPlugin(),
  withNgxsFormPlugin(),
  withNgxsReduxDevtoolsPlugin({
    disabled: environment.production,
    maxAge: 25,
  })
);
