import { subDays } from 'date-fns';

export const MaxAlarmsLimit = 30;
export const MaxAlarmPropertyLimit = 300;
export const LongPressDuration = 850;
export const AlarmPriorityIcon = 'fa-bell';
export const AlarmPinnedIcon = 'fa-thumbtack';
export const AlarmAutoDispatchIcon = 'fa-repeat-alt';
export const NuisanceIcon = 'fa-bullhorn';
export const StaleIcon = 'fa-hourglass-end';
export const WorkTicketIcon = 'fa-clipboard-list-check';
export const MultipleAlarmProperty = 'MULTIPLE';
export const NoAlarmTitle = 'No Alarm Message Available';
export const VfiWarnState = 'Warning';
export const VfiFaultState = 'Fault';
export const VfiUnreliableState = 'Unreliable';
export const AlarmPriorityColors = {
  5: 'priority-5',
  4: 'priority-4',
  3: 'priority-3',
  2: 'priority-2',
  1: 'priority-1',
  0: 'priority-0',
};

export enum TileCheckboxState {
  ALL = 'all',
  SELECTION = 'selection',
}

export enum BaseAlarmStatus {
  SNOOZED = 'Snoozed',
  INTAKE = 'Intake',
  DISPATCHED = 'Dispatched',
  INACTIVE = 'Inactive',
  ACTIVE_ALARMS = 'Active Alarms',
  ACTIVE_WORK = 'Active Work',
  DECLINED = 'Declined',
}

export enum AlarmState {
  ALARM = 'Alarm',
  NORMAL = 'Normal',
  WARN = 'Warn',
  UNRELIABLE = 'Unreliable',
  FAULT = 'Fault',
}

export const source = {
  SUPERBLOCK: 'ALERTON',
};
export const DefaultAlarmSort = {
  name: 'Alarm Time',
  dbName: 'LATEST_ALARM_TIME',
};

export const AlarmDetailsFields = `
  id
  text
  databaseId
  contextualName
  priority
  status
  isAutoDispatch
  instances_aggregate {
    aggregate {
      max {
        alarmTime
      }
    }
  }
`;

export const AlarmPropertyConfigurationFields = `
  buildings
  floors
  rooms
  mediums
  parameters
  conditions
  equipmentTypes
  equipment
  sensors
  spaceTypes
  systemTypes
  contacts
`;

export const AllAlarmFields = `
  formattedRawText
  activeWorkCount
  contextualName
  id
  displayId
  isAutoDispatch
  isPinned
  isStale
  latestAlarmTime
  latestEndTime
  lastWorkTicketClosedAt
  priority
  point
  status
  text
  databaseId
  activeWorkCount
  fleetingCount
  chatteringCount
  nuisanceCount
  lastFleetingCount
  lastChatteringCount
  lastNuisanceCount
  spaceType
  properties {
    type
    values
    confidence
    highlightConfidenceThreshold
  }
  cmmsAttributes
  externalSystem {
    displayName
    hasProcessVariables
  }
  notes
  entityIds
  entities {
    id
    name
    alarmId
    criticality
  }
  alarmType
  isServiceImpacting
  snoozeExpiresAt
  snoozeReason
  serviceImpactLastModifiedBy
  priorityLastModifiedBy
  pointEntityId
  pointEntityLastModifiedBy
  schema
  `;

export const AlarmRawAttributes = `
rawAttributes {
    field
    value
  }`;

export const ListAlarmFields = `
  formattedRawText
  activeWorkCount
  contextualName
  id
  displayId
  isAutoDispatch
  isPinned
  isStale
  latestAlarmTime
  latestEndTime
  lastWorkTicketClosedAt
  externalSystem {
    displayName
  }
  priority
  status
  state
  text
  fleetingCount
  chatteringCount
  nuisanceCount
  lastFleetingCount
  lastChatteringCount
  lastNuisanceCount
  entityIds
  properties {
    type
    values
  }
  entities {
    id
    name
    criticality
  }
  alarmAssetName
  alarmAssetType
  alarmName
  alarmType
  spaceType
  spaceName
  systemType
  systemName
  lastWorkTicketCreatedAt
  equipmentType
  equipmentName
  componentName
  componentType
  isServiceImpacting
  basPriority
  openWorkTickets {
    id
    displayId
    status
    state
    isResolved
  }
  snoozeExpiresAt
  snoozeReason
  `;

export const CurrentStart = subDays(new Date(), 30);
export const CurrentEnd = new Date();
export const PreviousEnd = CurrentStart;
export const PreviousStart = subDays(CurrentStart, 30);
export const ALARM_TILE_DRAG = 'ALARM_TILE_DRAG';
export const ALRAM_TILE_CREATE_WORK_DRAG = 'ALARM_TILE_CREATE_WORK_DRAG';

export enum AlarmActivityValues {
  true = 'True',
  false = 'False',
  '' = '—',
}

export const AlarmPriorityNames = {
  5: 'Highest',
  4: 'High',
  3: 'Medium',
  2: 'Low',
  1: 'Lowest',
  0: 'No Priority',
  '': '—',
};

export const AlarmPriorityValues = {
  Highest: 5,
  High: 4,
  Medium: 3,
  Low: 2,
  Lowest: 1,
  'No priority': 0,
};

export enum ALARM_TABLE_DB_NAME {
  externalSystem = 'EXTERNAL_SYSTEM',
}

export enum AlarmPropertyVoteType {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
  CHANGE = 'CHANGE',
}
export enum AlarmAssociatedWorkStatus {
  RECENTLY_CLOSED = 'RECENTLY_CLOSED',
  NOT_OPEN = 'NOT_OPEN',
  OPEN = 'OPEN',
}

// The list of states to show within alarm tiles
export const ALARM_TILE_ALARM_STATE_DISPLAY_LIST = [
  AlarmState.WARN.toUpperCase(),
  AlarmState.FAULT.toUpperCase(),
  AlarmState.UNRELIABLE.toUpperCase(),
];

export const ALARM_STATE_TO_TEXT_MAP = new Map<string, string>([
  [AlarmState.WARN.toUpperCase(), VfiWarnState],
  [AlarmState.FAULT.toUpperCase(), VfiFaultState],
  [AlarmState.UNRELIABLE.toUpperCase(), VfiUnreliableState],
]);

//Space/Asset fields
export const SPACE_ASSET_FIELDS = [
  'building',
  'floor',
  'space_type',
  'room_number',
  'room_name',
  'equipment',
  'equipment_type',
];

// Alarm Details Field Order
export const BASE_EQUIPMENT_FIELDS = [
  'component',
  'medium',
  'flow_direction',
  'parameter_prefix',
  'parameter',
  'condition',
];

export const ALARM_STATUSES = ['Intake', 'Snoozed', 'Dispatched', 'Declined'];
