import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  SimpleChanges,
  input,
  output,
  viewChild,
} from '@angular/core';
import {
  NEW_VALUE_TAG,
  SelectSize,
  VfiSelectOption,
  VfiSelectParentData,
} from '@vfi-ui/models';
import { fastParse, isNil } from '@vfi-ui/util/helpers';
import { NzPopoverDirective } from 'ng-zorro-antd/popover';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { VfiButtonComponent } from '../vfi-button/vfi-button.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@Component({
  selector: 'atom-vfi-select',
  templateUrl: './vfi-select.component.html',
  styleUrls: ['./vfi-select.component.scss'],
  imports: [
    NzPopoverDirective,
    NgClass,
    NgIf,
    VfiButtonComponent,
    InfiniteScrollDirective,
    NgFor,
  ],
})
export class VfiSelectComponent implements OnChanges {
  readonly searchInput = viewChild<ElementRef>('searchInput');
  readonly size = input(SelectSize.MEDIUM);
  readonly ariaLabel = input('vfi-select');
  readonly placeholder = input('');
  readonly value = input(undefined);
  readonly label = input<string>(undefined);
  readonly hint = input<string>(undefined);
  readonly required = input(false);
  readonly iconLeft = input<string>(undefined);
  readonly readOnly = input(false);
  readonly disabled = input(false);
  readonly subtle = input(false);
  readonly success = input(false);
  readonly warning = input(false);
  readonly warningMessage = input<string>(undefined);
  readonly error = input(false);
  readonly errorMessage = input<string>(undefined);
  readonly showSearch = input(true);
  readonly options = input<VfiSelectOption[]>([]);
  readonly loading = input(false);
  readonly parentData = input<VfiSelectParentData>(undefined);
  readonly canClear = input(false);
  readonly enableDropdown = input(true);
  readonly autofocus = input(false);
  readonly closeOnSelect = input(false);
  readonly placement = input(null);
  readonly allowNewValues = input(false);
  readonly cleared = output();
  readonly dropdownVisibleChanged = output<boolean>();
  readonly scrolled = output<boolean>();
  readonly newValueSelected = output<string>();
  readonly backSelected = output<VfiSelectParentData>();
  readonly optionSelected = output<VfiSelectOption>();
  showDropdown = false;
  formattedOptions: VfiSelectOption[] = [];

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.options?.currentValue) {
      this.formattedOptions = fastParse(this.options());
    }
  }

  /**
   * handle option select event
   *
   * @param {VfiSelectOption} option
   * @memberof VfiSelectComponent
   */
  onOptionSelect(option: VfiSelectOption) {
    if (this.allowNewValues()) {
      const formattedOption = option?.value
        .split(NEW_VALUE_TAG)
        .join('')
        .trim();
      this.optionSelected.emit({
        label: formattedOption,
        value: formattedOption,
      });
      this.newValueSelected.emit(formattedOption);
    } else {
      this.optionSelected.emit(option);
    }

    if (this.closeOnSelect()) {
      this.showDropdown = false;
      this.formattedOptions = this.options();
      this.onPopoverVisibleChange(false);
    }
  }

  /**
   * handle on search event
   *
   * @param {string} ev
   * @memberof VfiSelectComponent
   */
  onSearch(search: string) {
    const allowNewValues = this.allowNewValues();
    if (allowNewValues) {
      this.removeNewTag();
    }

    if (search) {
      this.formattedOptions = this.options().filter((op) =>
        op.label.toLowerCase().includes(search.toLowerCase())
      );
      if (!this.formattedOptions.length && allowNewValues) {
        this.addNewTag(search);
      }
    } else {
      this.formattedOptions = this.options();
    }
  }

  /**
   * handle on back select event
   *
   * @param {VfiSelectParentData} parent
   * @memberof VfiSelectComponent
   */
  onBackSelected(parent: VfiSelectParentData) {
    this.backSelected.emit(parent);
  }

  /**
   * handle popover visible change event
   *
   * @param {boolean} show
   * @memberof VfiSelectComponent
   */
  onPopoverVisibleChange(show: boolean) {
    this.dropdownVisibleChanged.emit(show);
    if (show) {
      this.autoFocusSearch();
    }
  }

  /**
   * handle select clicked event
   *
   * @memberof VfiSelectComponent
   */
  onSelectClicked() {
    if (!this.enableDropdown()) {
      this.dropdownVisibleChanged.emit(true);
    }
  }

  /**
   * handle options scroll event
   *
   * @memberof VfiSelectComponent
   */
  onOptionsScroll() {
    this.scrolled.emit(true);
  }

  /**
   * auto focus on search input
   *
   * @memberof VfiSelectComponent
   */
  autoFocusSearch() {
    this.cdr.detectChanges();
    if (this.autofocus() && this.showSearch()) {
      this.searchInput().nativeElement.focus();
    }
  }

  /**
   * format display values
   *
   * @param {*} value
   * @returns
   * @memberof VfiSelectComponent
   */
  formatDisplayValue(value) {
    const optionIndex = this.formattedOptions.findIndex(
      (o) => o?.value === value
    );
    return optionIndex > -1 ? this.formattedOptions[optionIndex].label : value;
  }

  /**
   * return if placeholder should be shown
   *
   * @param {*} value
   * @returns
   * @memberof VfiSelectComponent
   */
  showPlaceholder(value) {
    return isNil(value) || (Array.isArray(value) && !value.length);
  }

  /**
   * add new value option
   *
   * @param {string} value
   * @memberof VfiSelectComponent
   */
  addNewTag(value: string) {
    const newOption = `${value} ${NEW_VALUE_TAG}`;
    this.formattedOptions.push({ label: newOption, value: newOption });
  }

  /**
   * remove new option
   *
   * @memberof VfiSelectComponent
   */
  removeNewTag() {
    this.formattedOptions = this.formattedOptions.filter(
      (o) => !o.label.includes(NEW_VALUE_TAG)
    );
  }
}
