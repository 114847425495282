export enum AlarmsTableSortFields {
  alarmTime = 'LATEST_ALARM_TIME',
  latestAlarmTime = 'LATEST_ALARM_TIME',
  id = 'ID',
  priority = 'PRIORITY',
  text = 'TEXT',
  contextualName = 'CONTEXTUAL_NAME',
  status = 'STATUS',
  duration = 'DURATION',
  nuisanceBehavior = 'NUISANCE_BEHAVIOR',
  alarmAssetName = 'ALARM_ASSET_NAME',
  alarmName = 'ALARM_NAME',
  alarmType = 'ALARM_TYPE',
  equipmentName = 'EQUIPMENT_NAME',
  equipmentType = 'EQUIPMENT_TYPE',
  lastWorkTicketCreatedAt = 'LAST_WORK_TICKET_CREATED_AT',
  isStale = 'IS_STALE',
  systemName = 'SYSTEM_NAME',
  systemType = 'SYSTEM_TYPE',
  spaceType = 'SPACE_TYPE',
  spaceName = 'SPACE_NAME',
  workIcon = 'WORK_ICON',
  bellIcon = 'BELL_ICON',
  componentName = 'COMPONENT_NAME',
  componentType = 'COMPONENT_TYPE',
  isServiceImpacting = 'IS_SERVICE_IMPACTING',
  basPriority = 'BAS_PRIORITY',
  isPinned = 'IS_PINNED',
  nuisanceCount = 'NUISANCE_COUNT',
  assetCriticality = 'ASSET_CRITICALITY',
}

export enum AlarmsTableColumnDictionary {
  LATEST_ALARM_TIME = 'latestAlarmTime',
  ID = 'id ',
  PRIORITY = 'priority',
  TEXT = 'text',
  CONTEXTUAL_NAME = 'contextualName',
  STATUS = 'status',
  DURATION = 'duration',
  NUISANCE_BEHAVIOR = 'nuisanceBehavior',
  ALARM_ASSET_NAME = 'alarmAssetName',
  ALARM_ASSET_TYPE = 'alarmAssetType',
  ALARM_NAME = 'alarmName',
  ALARM_TYPE = 'alarmType',
  EQUIPMENT_NAME = 'equipmentName',
  EQUIPMENT_TYPE = 'equipmentType',
  LAST_WORK_TICKET_CREATED_AT = 'lastWorkTicketCreatedAt',
  IS_STALE = 'isStale',
  SYSTEM_NAME = 'systemName',
  SYSTEM_TYPE = 'systemType',
  SPACE_TYPE = 'spaceType',
  SPACE_NAME = 'spaceName',
  WORK_ICON = 'workIcon',
  BELL_ICON = 'bellIcon',
  COMPONENT_NAME = 'componentName',
  COMPONENT_TYPE = 'componentType',
  IS_SERVICE_IMPACTING = 'isServiceImpacting',
  BAS_PRIORITY = 'basPriority',
  IS_PINNED = 'isPinned',
  NUISANCE_COUNT = 'nuisanceCount',
  equipment = 'equipmentName',
  equipment_type = 'equipmentType',
  spaceNames = 'spaceName',
  space_type = 'spaceType',
}

export const DEFAULT_ALARMS_TABLE_COLUMNS = [
  'latestAlarmTime',
  'building',
  'alarmAssetName',
  'alarmName',
  'duration',
  'latestEndTime',
  'nuisanceCount',
  'openWorkTicketIds',
  'lastWorkTicketCreatedAt',
];

export const ALARMS_TABLE_PROPERTIES_BLACKLIST = [
  'equipment',
  'equipment_type',
  'space_type',
  'room_name',
  'room_number',
];

export const ALARM_SORT_PROPERTIES_BLACKLIST = ['room_name', 'room_number'];

export const ALARMS_TABLE_COLUMNS = [
  {
    name: 'Alarm Time',
    value: 'latestAlarmTime',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Location - Building',
    value: 'building',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarmed Asset Name',
    value: 'alarmAssetName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Type',
    value: 'alarmAssetType',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Name',
    value: 'alarmName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Time in Alarm',
    value: 'duration',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm End Time',
    value: 'latestEndTime',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Count (Nuisance)',
    value: 'nuisanceCount',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Work ID',
    value: 'openWorkTicketIds',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Latest Work Created',
    value: 'lastWorkTicketCreatedAt',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm ID',
    value: 'id',
    isVisible: true,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Contextual Name',
    value: 'contextualName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Priority',
    value: 'priority',
    isVisible: false,
    isEditable: true,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Source',
    value: 'externalSystemDisplayName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },

  {
    name: 'Asset Class',
    value: 'alarmType',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Status',
    value: 'status',
    isVisible: false,
    isEditable: true,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'BAS Priority',
    value: 'basPriority',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Alarm Category',
    value: 'basAlarmCategory',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Alarm Description',
    value: 'basAlarmDescription',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Alarm Message',
    value: 'basAlarmMessage',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Alarm Name',
    value: 'basAlarmName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Point Description',
    value: 'basPointDescription',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Nuisance Behavior',
    value: 'nuisanceBehavior',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Service Failure Alarm',
    value: 'isServiceImpacting',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Source Alarm Raw Text',
    value: 'text',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Stale Alarm',
    value: 'isStale',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Space Name',
    value: 'spaceName',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Space Type',
    value: 'spaceType',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Asset Criticality',
    value: 'assetCriticality',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
  {
    name: 'Alarm Condition',
    value: 'condition',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Category',
    value: 'category',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Flow Direction',
    value: 'flowDirection',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Medium',
    value: 'medium',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Parameter',
    value: 'parameter',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Parameter Descriptor',
    value: 'parameterPrefix',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Alarm Shop',
    value: 'shop',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Asset Component',
    value: 'component',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Location - Floor',
    value: 'floor',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: true,
  },
  {
    name: 'Source Alarm Point',
    value: 'point',
    isVisible: false,
    isEditable: false,
    subGroup: 'Alarm Fields',
    isAlarmAttribute: false,
  },
];
