import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, removeItem } from '@ngxs/store/operators';
import {
  AlarmLens,
  CriterionSelection,
  DefaultAlarmSort,
  DefaultNuisanceSort,
  DefaultWorkOrderSort,
  DEFAULT_ADD_CRITERION,
  filterModelMode,
  GlobalFilterSort,
  OrderDirection,
  TileType,
  BaseAlarmStatus,
  CoreAlarmsOrderSortField,
  LensCategory,
  Sort,
  DEFAULT_ACTIVE_ALARM_CRITERION,
  DEFAULT_ACTIVE_WORK_CRITERION,
} from '@vfi-ui/models';
import {
  fastParse,
  isNil,
  set,
  without,
  formatCriterionPills,
} from '@vfi-ui/util/helpers';
import {
  GlobalFiltersSearch,
  RemoveCoreGlobalFilters,
  RemoveGlobalFilterCriterion,
  ResetGlobalFilters,
  ResetOnlyCoreCriterionFilters,
  ResetOnlyCriterionFilters,
  SetCoreGlobalFilters,
  SetCoreSortValue,
  SetGlobalCurrentLens,
  SetGlobalFilters,
  SetResetFilters,
  SetSort,
  SetSortFilterType,
  SetSortValue,
  ToggleAlarmMobileActions,
  ToggleCreateLensFilterMode,
  ToggleFilterModal,
  ToggleFilterMode,
  ToggleSystemLensModal,
  TotalResetGlobalFilters,
} from './global-filters.actions';
import { AuthState } from '../auth/auth.state';
import { Injectable } from '@angular/core';
import { isEmpty } from '@vfi-ui/util/helpers';
import { GlobalFiltersService } from '@vfi-ui/data-access/shared';

export interface GlobalFiltersStateModel {
  searchTerm: string;
  sort: GlobalFilterSort | Sort[];
  coreSort: GlobalFilterSort | Sort[];
  type: TileType;
  criterion: CriterionSelection[];
  coreCriterion: CriterionSelection[];
  reset: boolean;
  mode: filterModelMode;
  showFilterModal: boolean;
  showAlarmMobileActions: boolean;
  showSystemLensModal: boolean;
  lens: AlarmLens;
  createLensCategory: LensCategory;
  diffChecker?: string;
}

const criterionDefault = [
  {
    model: null,
  },
];

export const globalFiltereStateDefaults: GlobalFiltersStateModel = {
  searchTerm: '',
  sort: [
    {
      field: CoreAlarmsOrderSortField.alarmTime,
      direction: OrderDirection.DESC,
    },
  ],
  coreSort: [
    {
      field: CoreAlarmsOrderSortField.alarmTime,
      direction: OrderDirection.DESC,
    },
  ],
  lens: {
    parent: '',
    child: '',
  },
  type: 'alarm',
  criterion: criterionDefault,
  coreCriterion: [],
  reset: false,
  mode: filterModelMode.FILTERS,
  showFilterModal: false,
  showAlarmMobileActions: false,
  showSystemLensModal: false,
  createLensCategory: null,
};

@State<GlobalFiltersStateModel>({
  name: 'globalFilters',
  defaults: globalFiltereStateDefaults,
})
@Injectable({
  providedIn: 'root',
})
export class GlobalFiltersState {
  constructor(
    private store: Store,
    private globalFiltersService: GlobalFiltersService
  ) {}

  @Selector()
  public static getState(state: GlobalFiltersStateModel) {
    return state;
  }

  @Selector()
  public static getSearchTerm(state: GlobalFiltersStateModel) {
    return state.searchTerm;
  }

  @Selector()
  public static getSort(state: GlobalFiltersStateModel) {
    return state.sort;
  }

  @Selector()
  public static getDefaultSort(state: GlobalFiltersStateModel) {
    return state.coreSort;
  }

  @Selector()
  public static getType(state: GlobalFiltersStateModel) {
    return state.type;
  }

  @Selector()
  public static getCreateLensCategory(state: GlobalFiltersStateModel) {
    return state.createLensCategory;
  }

  @Selector()
  public static isCriterionSet(state: GlobalFiltersStateModel) {
    const hasSearch = !isEmpty(state.searchTerm);
    const hasCriterion =
      state.criterion.length > 0 && !isNil(state.criterion[0].model);
    return hasSearch || hasCriterion;
  }

  @Selector()
  public static getCriterion(state: GlobalFiltersStateModel) {
    return state.criterion;
  }

  @Selector()
  public static GlobalSortFilterChange(state: GlobalFiltersStateModel) {
    const changed = {
      sort: state.sort,
      searchTerm: state.searchTerm,
      coreSort: state.coreSort,
      type: state.type,
      criterion: state.criterion,
      coreCriterion: state.coreCriterion,
      reset: state.reset,
    };
    return {
      ...changed,
      diffChecker: JSON.stringify(changed),
    } as Partial<GlobalFiltersStateModel>;
  }

  @Selector()
  public static hasCriterion(state: GlobalFiltersStateModel) {
    return (
      state.coreCriterion.filter((crit) => crit.model).length > 0 ||
      state.criterion.filter((crit) => crit.model).length > 0
    );
  }

  @Selector()
  public static getCoreCriterionPills(state: GlobalFiltersStateModel) {
    return formatCriterionPills(state.coreCriterion);
  }

  @Selector()
  public static getCriterionPills(state: GlobalFiltersStateModel) {
    return formatCriterionPills(state.criterion);
  }

  @Selector()
  public static getCoreCriterion(state: GlobalFiltersStateModel) {
    return state.coreCriterion;
  }

  @Selector()
  public static getAllCriterion(state: GlobalFiltersStateModel) {
    return [...state.coreCriterion, ...state.criterion];
  }

  @Selector()
  public static getCriterionByMode(state: GlobalFiltersStateModel) {
    if (state.mode === filterModelMode.EDIT_LENS) {
      return [...state.coreCriterion, ...state.criterion];
    }
    return state.criterion;
  }

  @Selector()
  public static getCoreCriterionByMode(state: GlobalFiltersStateModel) {
    if (state.mode === filterModelMode.EDIT_LENS) {
      return [];
    }
    if (state.mode === filterModelMode.CREATE_LENS && state.type === 'alarm') {
      return [DEFAULT_ACTIVE_ALARM_CRITERION];
    }
    if (
      state.mode === filterModelMode.CREATE_LENS &&
      state.type === 'activeWork'
    ) {
      return [DEFAULT_ACTIVE_WORK_CRITERION];
    }
    if (state.mode === filterModelMode.CREATE_LENS && state.type === 'assets') {
      return [];
    }
    return state.coreCriterion;
  }

  @Selector()
  static getLens(state: GlobalFiltersStateModel) {
    return state.lens;
  }

  @Selector()
  static getLensId(state: GlobalFiltersStateModel) {
    return state.lens.parentId;
  }

  @Selector()
  static getLensInput(state: GlobalFiltersStateModel) {
    return {
      name: state.lens.child,
      description: state.lens.description,
      sort: Array.isArray(state.sort) ? state.sort : [state.sort],
      category: state.lens.category,
    };
  }

  @Selector()
  static getFiltersMode(state: GlobalFiltersStateModel) {
    return state.mode;
  }

  @Selector()
  static isFilterModelOpen(state: GlobalFiltersStateModel) {
    return state.showFilterModal;
  }

  @Selector()
  static isSytemLensModalOpen(state: GlobalFiltersStateModel) {
    return state.showSystemLensModal;
  }

  @Selector()
  public static isAlarmMobileActionsOpen(state: GlobalFiltersStateModel) {
    return state.showAlarmMobileActions;
  }

  @Selector()
  static hasStatusFilter(state: GlobalFiltersStateModel) {
    return !isNil(state.coreCriterion.find((r) => r.model === 'statuses'));
  }

  @Selector()
  static getAppliedCriterionCount(state: GlobalFiltersStateModel) {
    return state.criterion.filter((crit) => !isNil(crit.model)).length;
  }

  @Action(SetGlobalCurrentLens)
  public currentLens(ctx: StateContext<GlobalFiltersStateModel>, { payload }) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      lens: {
        ...state.lens,
        ...payload,
      },
    });
  }

  @Action(ToggleFilterMode)
  public toggleFiltersMode(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        mode: payload,
        createLensCategory: null,
      })
    );
  }

  @Action(ToggleCreateLensFilterMode)
  public toggleCreateFiltersMode(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        mode: payload.mode,
        createLensCategory: payload.category,
      })
    );
  }

  @Action(ToggleFilterModal)
  public toggleFilterModal(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        showFilterModal: payload,
      })
    );
  }

  @Action(ToggleSystemLensModal)
  public toggleSystemLensModal(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        showSystemLensModal: payload,
      })
    );
  }

  @Action(ToggleAlarmMobileActions)
  public togglesAlarmMobileActions(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }
  ) {
    ctx.setState(
      patch({
        showAlarmMobileActions: payload,
      })
    );
  }

  @Action(GlobalFiltersSearch)
  public add(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: GlobalFiltersSearch
  ) {
    const stateModel = ctx.getState();
    ctx.setState({
      ...stateModel,
      searchTerm: payload,
    });
  }

  @Action(SetSort)
  public Sort(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetSort
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sort: payload as Sort[],
    });
  }

  @Action(SetSortValue)
  public sortValue(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetSortValue
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      sort: {
        ...state.sort,
        ...payload,
      },
    });
  }

  @Action(SetCoreSortValue)
  public defaultSortValue(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetSortValue
  ) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      coreSort: {
        ...state.sort,
        ...payload,
      },
    });
  }

  @Action(SetSortFilterType)
  public sortFilterType(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetSortFilterType
  ) {
    ctx.setState(
      patch({
        type: payload,
      })
    );
  }

  @Action(ResetGlobalFilters)
  public resetState(ctx: StateContext<GlobalFiltersStateModel>) {
    const state = ctx.getState();
    ctx.setState({
      ...globalFiltereStateDefaults,
      searchTerm: '',
      sort: state.sort,
      lens: state.lens,
      type: state.type,
      criterion: state.criterion,
      coreCriterion: state.coreCriterion,
      reset: false,
    });
  }

  @Action(ResetOnlyCriterionFilters)
  public resetOnlyCriterionFilters(ctx: StateContext<GlobalFiltersStateModel>) {
    ctx.setState(
      patch({
        criterion: criterionDefault,
      })
    );
  }

  @Action(ResetOnlyCoreCriterionFilters)
  public resetOnlyCoreCriterionFilters(
    ctx: StateContext<GlobalFiltersStateModel>
  ) {
    ctx.setState(
      patch({
        coreCriterion: globalFiltereStateDefaults.coreCriterion,
      })
    );
  }

  @Action(TotalResetGlobalFilters)
  public totalResetState(
    ctx: StateContext<GlobalFiltersStateModel>,
    { reset }
  ) {
    const state = ctx.getState();
    let sortValue = null;
    let sortDbName = null;
    switch (state.type) {
      case 'alarm':
        sortValue = DefaultAlarmSort.name;
        sortDbName = DefaultAlarmSort.dbName;
        break;
      case 'nuisance':
        sortValue = DefaultNuisanceSort.name;
        sortDbName = DefaultNuisanceSort.dbName;
        break;
      case 'activeWork':
        sortValue = DefaultWorkOrderSort.sortValue;
        sortDbName = DefaultWorkOrderSort.sortDbName;
        break;
      case 'allWork':
        sortValue = DefaultWorkOrderSort.sortValue;
        sortDbName = DefaultWorkOrderSort.sortDbName;
        break;
    }
    ctx.setState({
      ...globalFiltereStateDefaults,
      searchTerm: '',
      sort: {
        sortValue,
        sortType: OrderDirection.DESC,
        sortValue2: null,
        parentSortValue: null,
        sortDbName,
      },
      lens: state.lens,
      type: state.type,
      criterion: criterionDefault,
      coreCriterion: state.coreCriterion,
      showFilterModal: state.showFilterModal,
      showSystemLensModal: state.showSystemLensModal,
      reset: !!reset,
    });
  }

  @Action(SetResetFilters)
  public setReset(ctx: StateContext<GlobalFiltersStateModel>, { reset }) {
    ctx.setState(
      patch({
        reset: !!reset,
      })
    );
  }

  @Action(RemoveGlobalFilterCriterion)
  public removeCriterion(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: RemoveGlobalFilterCriterion
  ) {
    const state = ctx.getState();
    const criterion = state.criterion.filter(
      (crit) => crit.detail.name !== payload.detail.name
    );
    ctx.setState(
      patch({
        criterion: criterion.length ? criterion : [DEFAULT_ADD_CRITERION],
      })
    );
    this.globalFiltersService.onFiltersUpdate();
  }

  @Action(SetGlobalFilters)
  public addGlobalFilters(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetGlobalFilters
  ) {
    const hasWork = this.store.selectSnapshot(AuthState.hasWorkProduct);
    const criterion = hasWork ? payload : this.updateWorkStatuses(payload);
    ctx.setState(
      patch({
        criterion: criterion.filter((filter) => !isNil(filter.detail)),
      })
    );
    this.globalFiltersService.onFiltersUpdate();
  }

  @Action(SetCoreGlobalFilters)
  public addCoreGlobalFilters(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: SetCoreGlobalFilters
  ) {
    const hasWork = this.store.selectSnapshot(AuthState.hasWorkProduct);
    const criterion = hasWork ? payload : this.updateWorkStatuses(payload);
    ctx.setState(
      patch({
        coreCriterion: criterion.filter((filter) => !isNil(filter.detail)),
      })
    );
  }

  @Action(RemoveCoreGlobalFilters)
  public removeCoreCriterion(
    ctx: StateContext<GlobalFiltersStateModel>,
    { payload }: RemoveCoreGlobalFilters
  ) {
    ctx.setState(
      patch({
        coreCriterion: removeItem(
          (crit: CriterionSelection) => crit.detail.name === payload.detail.name
        ),
      })
    );
  }

  /**
   * removes work status if there is no work product
   *
   * @private
   * @param {CriterionSelection[]} criterion
   * @returns
   * @memberof GlobalFiltersState
   */
  private updateWorkStatuses(criterion: CriterionSelection[]) {
    const statusCrit = criterion.findIndex((r) => r.model === 'statuses');
    if (statusCrit !== -1) {
      const crit = fastParse(criterion);
      const updatedValues = without(
        crit[statusCrit].value,
        BaseAlarmStatus.DISPATCHED
      );
      set(crit[statusCrit], 'value', updatedValues);
      set(crit[statusCrit], 'detail.selection.value', updatedValues);
      return crit;
    }
    return criterion;
  }
}
