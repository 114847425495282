<ng-container
  *ngIf="
    ((mode() !== filterModelMode.CREATE_LENS && device() === deviceAgent.MOBILE) ||
      device() === deviceAgent.DESKTOP) &&
    showCoreCriterion()
  "
>
  <div
    class="d-flex align-items-center pb-1"
    *ngFor="let selection of coreCriterion(); let index = index; let last = last"
  >
    <ng-container
      *ngTemplateOutlet="
        coreFilterRowTemplate;
        context: {
          selection: selection,
          index: index,
          last: last,
          show: selection?.detail?.dbName !== 'isRecentlyClosed',
          disabled: true,
        }
      "
    ></ng-container>
  </div>
</ng-container>

<div
  class="d-flex align-items-center pb-1"
  *ngFor="let selection of _criterion(); let index = index; let last = last"
>
  <ng-container
    *ngTemplateOutlet="
      critFilterRowTemplate;
      context: {
        selection: selection,
        index: index,
        last: last,
        disabled: isReadonlyFilter(selection, mode(), createLensCategory()),
      }
    "
  ></ng-container>
</div>
<ng-template
  #coreFilterRowTemplate
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
  let-show="show"
>
  <div class="crit-row d-flex" *ngIf="show">
    <i class="fa-solid fa-lock action-icon-width mr-2 dm100"></i>
    <ng-container
      *ngTemplateOutlet="
        selectionFields;
        context: {
          selection: selection,
          disabled: disabled,
          index: index,
          last: last,
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template
  #critFilterRowTemplate
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
>
  <div
    class="crit-row-wrapper"
    [class.align-items-baseline]="
      selection.detail && selection.detail.type === 'minMax'
    "
  >
    <div class="d-flex align-items-center">
      <i
        *ngIf="(!last && disabled) || !allowDelete()"
        class="fa-solid fa-lock action-icon-width mr-2 dm100"
      ></i>
      <i
        *ngIf="!last && !disabled && allowDelete()"
        class="action-icon-width fa-solid fa-minus red-6 regular-14 cursor-pointer mr-2"
        (click)="removeCriterion(index)"
      ></i>
      <i
        aria-label="addFilterBtn"
        *ngIf="last && !disabled && allowDelete()"
        class="action-icon-width fa-solid fa-plus green-7 regular-14 mr-2"
      ></i>
    </div>

    <ng-container
      *ngTemplateOutlet="
        selectionFields;
        context: {
          selection: selection,
          disabled: disabled,
          index: index,
          last: last,
        }
      "
    ></ng-container>
  </div>
</ng-template>

<ng-template
  #selectionFields
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
  let-last="last"
>
  <div
    class="selection-wrapper"
    [class.min-max-selection-wrapper]="
      selection.detail && selection.detail.type === 'minMax'
    "
  >
    <div class="ml-1 criterion-select-wrapper">
      <atom-vfi-select
        [value]="selection?.model?.name || selection?.model"
        placeholder="Select from dropdown"
        [options]="filtersCriterionOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="
          handleSelectionChange(index, $event); criterionSelected($event, index)
        "
      ></atom-vfi-select>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'alarmSource'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          alarmSource;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'cmmsSource'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          cmmsSource;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'select'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          select;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'multiLevelSelector'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          multiLevelSelector;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="
        selection.detail &&
        selection.detail.type === 'dateTime' &&
        device() !== deviceAgent.MOBILE
      "
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          dateTime;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="
        selection.detail &&
        selection.detail.type === 'dateTime' &&
        device() === deviceAgent.MOBILE
      "
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          mobileDateTime;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>

    <div
      *ngIf="selection.detail && selection.detail.type === 'number'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          number;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'priority'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          priority;
          context: {
            selection: selection,
            disabled: disabled,
            index: index,
            priorities: priorities,
          }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'status'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          status;
          context: {
            selection: selection,
            disabled: disabled,
            index: index,
            statusFilters: statusFilters,
          }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'bool'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          bool;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'customValueSelect'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          customValueSelect;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'customWorkSelect'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          customWorkSelect;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'minMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          minMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'durationMinMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          durationMinMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'timeMinMax'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          timeMinMax;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'text'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          text;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
    <div
      *ngIf="selection.detail && selection.detail.type === 'userSelect'"
      class="d-flex align-items-center"
    >
      <ng-container
        *ngTemplateOutlet="
          userSelect;
          context: { selection: selection, disabled: disabled, index: index }
        "
      ></ng-container>
    </div>
  </div>
</ng-template>

<ng-template
  #alarmSource
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-vfi-select-multiple
        class="w-100"
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [readOnly]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : []
        "
        placeholder="{{ selection.detail.name }}"
        [fullValues]="selection?.detail?.selection?.fullValues"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [required]="
          !hasReqCriterion(selection.detail.dbName) &&
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></atom-vfi-select-multiple>
    </div>
  </div>
</ng-template>

<!-- TODO: Find where we are using cmmsSource filter -->
<ng-template
  #cmmsSource
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode() === filterModelMode.FILTERS ||
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode() !== filterModelMode.FILTERS &&
          type() !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-select-multiple
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [cmmsSource]="true"
        [disabled]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : null
        "
        placeholder="{{ selection.detail.name }}"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [classes]="
          !hasReqCriterion(selection.detail.dbName) &&
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'required-field'
            : ''
        "
        [required]="required()"
      ></atom-select-multiple>
    </div>
  </div>
</ng-template>

<ng-template
  #select
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="w-100 filterMultiSelect d-flex">
      <atom-vfi-select-multiple
        class="w-100"
        [name]="selection.detail.name"
        [customQueryType]="selection.detail.dbName"
        [customQuery]="selection.detail.query"
        [readOnly]="disabled"
        [width]="229"
        [appendTo]="'body'"
        [addBlanks]="selection.detail.blanks"
        [useFullValues]="!disabled"
        [value]="
          selection.detail?.selection?.value?.length
            ? selection.detail?.selection?.value
            : []
        "
        [fullValues]="selection?.detail?.selection?.fullValues"
        [allowNewValues]="selection?.detail?.newValues"
        placeholder="{{ selection.detail.name }}"
        (fullValuesChanged)="fullValuesInputChanged($event, index)"
        [required]="required()"
      ></atom-vfi-select-multiple>
    </div>
  </div>
</ng-template>

<!-- TODO: Find where multiLevelSelector filter is being used -->
<ng-template
  #multiLevelSelector
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-padding pl-2">
      <h4 class="mb-0 min-width-includes">includes</h4>
    </div>
    <div class="w-100 multiLevelSelector d-flex">
      <atom-point-entity-selector
        class="w-100"
        placeholder="Location of the entity"
        [value]="selection?.detail?.selection"
        [pointEntity]="selection?.detail?.selection?.name[0]"
        (entityChanged)="multiLevelSelectorChanged($event, index)"
      ></atom-point-entity-selector>
    </div>
  </div>
</ng-template>

<ng-template
  #dateTime
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper d-flex align-items-center between-padding">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <nz-range-picker
        class="vfi-range-selector range-selector"
        [nzSize]="'large'"
        [(ngModel)]="selection.selection"
        [nzDisabled]="disabled"
        nzFormat="MM/d/yyyy h:mm a"
        [nzPlaceHolder]="['Start Time', 'End Time']"
        [nzShowTime]="{ nzFormat: 'h:mm a', nzUse12Hours: true }"
        (ngModelChange)="rangeInputChanged($event, index)"
        [ngClass]="{
          'required-field':
            (!hasReqCriterion(selection.detail.dbName) &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required(),
        }"
      ></nz-range-picker>
    </div>
  </div>
</ng-template>

<!-- TODO: Handle mobile date time -->
<ng-template
  #mobileDateTime
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper between-padding">
    <div class="pr-2 pl-2">
      <h4
        class="mb-0 pr-2"
        *ngIf="
          mode() === filterModelMode.FILTERS ||
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode() !== filterModelMode.FILTERS &&
          type() !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="d-flex flex-column">
      <nz-date-picker
        class="date-select-width imb-1"
        [nzDropdownClassName]="'calendar-input-none'"
        [nzSize]="'large'"
        [ngModel]="
          selection.selection ? selection.selection[0] : selection.selection
        "
        [nzDisabled]="disabled"
        nzFormat="MM/dd/yyyy HH:mm a"
        [nzPlaceHolder]="'Start Time'"
        (ngModelChange)="
          selection.selection = mobileDateTimeChanged($event, 0, index)
        "
        [ngClass]="{
          'required-field':
            (!hasReqCriterion(selection.detail.dbName) &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required(),
        }"
        [nzInputReadOnly]="true"
      ></nz-date-picker>
      <nz-date-picker
        class="date-select-width"
        [nzDropdownClassName]="'calendar-input-none'"
        [nzSize]="'large'"
        [ngModel]="
          selection.selection ? selection.selection[1] : selection.selection
        "
        [nzDisabled]="disabled"
        nzFormat="MM/dd/yyyy HH:mm a"
        [nzPlaceHolder]="'End Time'"
        (ngModelChange)="
          selection.selection = mobileDateTimeChanged($event, 1, index)
        "
        [ngClass]="{
          'required-field':
            (!hasReqCriterion(selection.detail.dbName) &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required(),
        }"
        [nzInputReadOnly]="true"
      ></nz-date-picker>
    </div>
  </div>
</ng-template>

<!-- TODO: Find where number filter is being used -->
<ng-template
  #number
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <h4 class="mb-0 pr-3">includes</h4>
    <nz-input-number
      [(ngModel)]="selection.value"
      (ngModelChange)="valueInputChanged($event, index)"
      [nzPlaceHolder]="0"
      [nzSize]="'large'"
      [nzDisabled]="disabled"
      [nzPrecision]="0"
      [nzMin]="0"
      [nzStep]="1"
      [ngClass]="{
        'required-field':
          (!selection.value &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required(),
      }"
    ></nz-input-number>
  </div>
</ng-template>

<ng-template
  #priority
  let-selection="selection"
  let-priorities="priorities"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-multi-select
        placeholder="Priority"
        [value]="selection.detail?.selection?.value || []"
        [options]="priorities"
        [showSearch]="false"
        [subtle]="false"
        [readOnly]="disabled"
        [required]="
          ((!selection.value || !selection.value.length) &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        (optionSelected)="valueInputChanged($event, index, selection)"
      ></atom-vfi-multi-select>
    </div>
  </div>
</ng-template>

<ng-template
  #status
  let-selection="selection"
  let-statusFilters="statusFilters"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-multi-select
        placeholder="Status"
        [value]="selection.detail?.selection?.value || []"
        [options]="statusFilters"
        [showSearch]="false"
        [subtle]="false"
        [readOnly]="disabled"
        [required]="
          (!selection.value &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        (optionSelected)="valueInputChanged($event, index, selection)"
      ></atom-vfi-multi-select>
    </div>
  </div>
</ng-template>

<ng-template
  #bool
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-select
        [value]="selection.detail?.selection?.value"
        [placeholder]="
          selection?.model?.name || selection?.detail?.name || selection?.name
        "
        [options]="boolOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        [required]="
          (!hasValue(selection.value) &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        placement="bottom"
        (optionSelected)="valueInputChanged($event, index, selection)"
      ></atom-vfi-select>
    </div>
  </div>
</ng-template>

<ng-template
  #userSelect
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-assignee-selector
        [placeholder]="selection.detail.name"
        [multiSelect]="true"
        [value]="selection.value"
        [readOnly]="disabled"
        (assigneeChanged)="
          userInputChanged($event, index); selection.value = $event
        "
      >
      </atom-vfi-assignee-selector>
    </div>
  </div>
</ng-template>

<ng-template
  #customValueSelect
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-multi-select
        *ngIf="selection?.detail?.multi"
        [placeholder]="selection.detail.name"
        [value]="
          selection.detail?.selection?.value ||
          selection?.detail?.selection ||
          []
        "
        [options]="selection.detail.selectValues"
        [showSearch]="false"
        [subtle]="false"
        [readOnly]="disabled"
        [required]="
          (!selection.value &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        (optionSelected)="valueInputChanged($event, index, selection)"
      ></atom-vfi-multi-select>
      <atom-vfi-select
        *ngIf="!selection?.detail?.multi"
        [value]="
          !isNil(selection.detail?.selection?.value)
            ? selection.detail?.selection?.value
            : selection?.detail?.selection
        "
        [placeholder]="selection.detail.name"
        [options]="selection.detail.selectValues"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        [required]="
          (!selection.value &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        placement="bottom"
        (optionSelected)="valueInputChanged($event, index, selection)"
      ></atom-vfi-select>
    </div>
  </div>
</ng-template>

<!-- TODO: Find where custom work select filter is being used -->
<ng-template
  #customWorkSelect
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode() === filterModelMode.FILTERS ||
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'that is'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode() !== filterModelMode.FILTERS &&
          type() !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <atom-select-multiple
      class="ml-3"
      [useIds]="true"
      [name]="selection.detail.dbName"
      [addBlanks]="selection.detail.blanks"
      customQuery="workFields"
      [width]="229"
      [appendTo]="'body'"
      [value]="
        selection.detail?.selection?.fullValues?.length
          ? selection.detail?.selection?.fullValues
          : null
      "
      placeholder="Select a {{ selection.detail.name }}"
      (fullValuesChanged)="fullValuesInputChanged($event, index)"
    ></atom-select-multiple>
  </div>
</ng-template>

<ng-template
  #minMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper min-max-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="w-100" nz-row>
      <div class="select-wrapper d-flex align-items-center">
        <atom-vfi-input
          class="mr-1"
          label="Min {{
            selection?.model?.minMaxType || selection?.minMaxType
              ? selection?.model?.minMaxType || selection?.minMaxType
              : ''
          }}"
          placeholder="Min {{
            selection?.model?.minMaxType || selection?.minMaxType
              ? selection?.model?.minMaxType || selection?.minMaxType
              : ''
          }}"
          [value]="selection.detail?.selection?.min"
          type="number"
          [required]="
            (!isNil(selection.detail?.selection?.min) &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          (valueChanged)="minMaxInputChanged($event, index, 'min')"
        ></atom-vfi-input>
        <atom-vfi-input
          label="Max {{
            selection?.model?.minMaxType || selection?.minMaxType
              ? selection?.model?.minMaxType || selection?.minMaxType
              : ''
          }}"
          placeholder="Max {{
            selection?.model?.minMaxType || selection?.minMaxType
              ? selection?.model?.minMaxType || selection?.minMaxType
              : ''
          }}"
          [value]="selection.detail?.selection?.max"
          type="number"
          [required]="
            (!isNil(selection.detail?.selection?.max) &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          (valueChanged)="minMaxInputChanged($event, index, 'max')"
        ></atom-vfi-input>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #durationMinMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="w-100 d-flex flex-column" nz-row>
      <div class="select-wrapper d-flex align-items-center">
        <atom-vfi-input
          label="Min (Days)"
          placeholder="Min (Days)"
          [value]="selection.detail?.selection?.minDays"
          type="number"
          [required]="
            (!selection.detail?.selection?.minDays &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'minDays')"
        ></atom-vfi-input>
        <atom-vfi-input
          class="mr-1 ml-1"
          label="Min (Hrs)"
          placeholder="Min (Hrs)"
          [value]="selection.detail?.selection?.minHrs"
          type="number"
          [required]="
            (!selection.detail?.selection?.minHrs &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'minHrs')"
        ></atom-vfi-input>
        <atom-vfi-input
          label="Min (Mins)"
          placeholder="Min (Mins)"
          [value]="selection.detail?.selection?.minMins"
          type="number"
          [required]="
            (!selection.detail?.selection?.minMins &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'minMins')"
        ></atom-vfi-input>
      </div>
      <div class="select-wrapper d-flex align-items-center mt-1">
        <atom-vfi-input
          label="Max (Days)"
          placeholder="Max (Days)"
          [value]="selection.detail?.selection?.maxDays"
          type="number"
          [required]="
            (!selection.detail?.selection?.maxDays &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'maxDays')"
        ></atom-vfi-input>
        <atom-vfi-input
          class="mr-1 ml-1"
          label="Max (Hrs)"
          placeholder="Max (Hrs)"
          [value]="selection.detail?.selection?.maxHrs"
          type="number"
          [required]="
            (!selection.detail?.selection?.maxHrs &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'maxHrs')"
        ></atom-vfi-input>
        <atom-vfi-input
          label="Max (Mins)"
          placeholder="Max (Mins)"
          [value]="selection.detail?.selection?.maxMins"
          type="number"
          [required]="
            (!selection.detail?.selection?.maxMins &&
              type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
            required()
          "
          [readOnly]="disabled"
          oninput="validity.valid||(value='');"
          [pattern]="[0 - 9]"
          (valueChanged)="minMaxInputChanged($event, index, 'maxMins')"
        ></atom-vfi-input>
      </div>
    </div>
  </div>
</ng-template>

<!-- TODO: Find where timeMinMax filter is being used -->
<ng-template
  #timeMinMax
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper">
    <div class="includes-padding pl-2">
      <h4
        class="mb-0 min-width-includes"
        *ngIf="
          mode() === filterModelMode.FILTERS ||
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      >
        {{
          type() === criterionSelectionType.NOTIFICATION_EXCLUDES
            ? 'between'
            : selection?.detail?.isExclusion
              ? 'excludes'
              : 'includes'
        }}
      </h4>
      <nuclei-include-exclude
        [disabled]="disabled"
        class="include-exclude-padding-padding"
        [isExclusion]="selection?.detail?.isExclusion"
        (selected)="markExclusion($event === 'excludes', index)"
        *ngIf="
          mode() !== filterModelMode.FILTERS &&
          type() !== criterionSelectionType.NOTIFICATION_EXCLUDES
        "
      ></nuclei-include-exclude>
    </div>
    <div class="w-100 d-flex flex-column" nz-row>
      <div class="d-flex align-items-center">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minDays)"
        >
          <input
            type="number"
            maxlength="255"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minDays"
            (change)="minMaxInputChanged($event.target.value, index, 'minDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minDays &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Min (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minHrs)"
        >
          <input
            type="number"
            maxlength="23"
            min="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'minHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minHrs &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Min (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.minMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="59"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.minMins"
            (change)="minMaxInputChanged($event.target.value, index, 'minMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.minMins &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Min (Mins)</label>
        </div>
      </div>
      <div class="d-flex align-items-center mt-1">
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxDays)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxDays"
            (change)="minMaxInputChanged($event.target.value, index, 'maxDays')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxDays &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Max (Days)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxHrs)"
        >
          <input
            type="number"
            [min]="0"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxHrs"
            (change)="minMaxInputChanged($event.target.value, index, 'maxHrs')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxHrs &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Max (Hrs)</label>
        </div>
        <div
          class="input-wrapper min-max mb-xs-1 mb-sm-0 mr-sm-1 mr-xs-0"
          [class.input-active]="!isNil(selection.detail?.selection?.maxMins)"
        >
          <input
            type="number"
            min="0"
            max="59"
            oninput="validity.valid||(value='');"
            pattern="[0-9]"
            maxlength="255"
            class="form-control"
            [class.disabled-input]="disabled"
            [value]="selection.detail?.selection?.maxMins"
            (change)="minMaxInputChanged($event.target.value, index, 'maxMins')"
            [disabled]="disabled"
            [ngClass]="{
              'required-field':
                (!selection.detail?.selection?.maxMins &&
                  type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
                required(),
            }"
          />
          <label>Max (Mins)</label>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template
  #text
  let-selection="selection"
  let-index="index"
  let-disabled="disabled"
>
  <div class="sub-selection-wrapper contains-padding">
    <div class="includes-excludes-width mr-1 ml-1">
      <atom-vfi-select
        [value]="selection?.detail?.isExclusion ? 'excludes' : 'includes'"
        [options]="includeExcludeOptions"
        [showSearch]="false"
        [closeOnSelect]="true"
        [readOnly]="disabled"
        placement="bottom"
        (optionSelected)="markExclusion($event?.value === 'excludes', index)"
      ></atom-vfi-select>
    </div>
    <div class="select-wrapper">
      <atom-vfi-input
        [placeholder]="selection.detail.name"
        [value]="selection.detail?.selection?.value"
        [readOnly]="disabled"
        [required]="
          (!selection.detail?.selection?.value &&
            type() === criterionSelectionType.NOTIFICATION_EXCLUDES) ||
          required()
        "
        [allowSpecialCharacters]="false"
        (valueChanged)="valueInputChanged($event, index, selection)"
      ></atom-vfi-input>
    </div>
  </div>
</ng-template>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
