import {
  CREATE_EXTERNAL_CMMS_FIELD_RELATION_QUERY,
  CREATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
  DELETE_CMMS_FIELD_TRANSLATIONS_MUTATION,
  DELETE_CMMS_FIELD_VALUES_MUTATION,
  DELETE_EXTERNAL_CMMS_FIELD_RELATION_QUERY,
  DELETE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
  UPDATE_CMMS_CONFIG_QUERY,
  UPDATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
} from './../queries/cmms.query';
import { Injectable } from '@angular/core';
import {
  CmmsExternalFieldCreateInput,
  CmmsExternalFieldUpdateInput,
  ERROR_CREATE_CMMS_DOMAIN_VALUE,
  ERROR_UPDATE_CMMS_DOMAIN_VALUE,
  ERROR_CREATE_CMMS_FIELD,
  ERROR_PULLING_CMMS_FIELD,
  ERROR_UPDATE_CMMS_FIELD,
  ExternalCmmsDomainValue,
  ExternalCmmsDomainValueCreateInput,
  ExternalCmmsDomainValueSortField,
  ExternalCmmsDomainValueUpdateInput,
  ExternalCmmsDomainValueWhere,
  ExternalCmmsField,
  ExternalCmmsFieldSortField,
  OrderDirection,
  ExternalCmmsFieldWhere,
  ERROR_PULLING_CMMS_DOMAIN_VALUE,
  CmmsFieldMapping,
  CmmsValueTranslation,
  ERROR_CREATE_CMMS_FIELD_MAPPING,
  ERROR_DELETE_CMMS_FIELD_MAPPING,
  ERROR_CREATE_CMMS_VALUE_TRANSLATION,
  ERROR_UPDATE_CMMS_VALUE_TRANSLATION,
  ERROR_DELETE_CMMS_VALUE_TRANSLATION,
  ExternalCmmsValueTranslationCreateInput,
  ExternalCmmsValueTranslationUpdateInput,
  ExternalCmmsMappings,
  ExternalCmmsFieldMappingCreateInput,
  ERROR_DELETE_CMMS_FIELD_RELATION,
  ERROR_CREATE_CMMS_FIELD_RELATION,
  ERROR_DELETE_CMMS_FIELD_VALUES,
  ERROR_DELETE_CMMS_FIELD_TRANSLATIONS,
  ERROR_DOWNLOAD_CSV,
  UpdateCmmsConfigInput,
  ERROR_UPDATE_CMMS_CONFIG,
} from '@vfi-ui/models';
import { Apollo } from 'apollo-angular';
import { catchError, filter, map, Observable, tap, throwError } from 'rxjs';
import {
  CREATE_CMMS_DOMAIN_VALUE_QUERY,
  CREATE_EXTERNAL_CMMS_QUERY,
  EXTENRAL_CMMS_DOMAIN_VALUE_QUERY,
  EXTENRAL_CMMS_FIELDS_QUERY,
  UPDATE_CMMS_DOMAIN_VALUE_QUERY,
  PULL_EXTERNAL_CMMS_FIELDS_QUERY,
  UPDATE_EXTERNAL_CMMS_QUERY,
  PULL_EXTERNAL_CMMS_DOMAIN_VALUE_QUERY,
  EXTENRAL_CMMS_FIELD_MAPPIPNG_QUERY,
  EXTENRAL_CMMS_VALUE_TRANSLATIONS_QUERY,
  CREATE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY,
  DELETE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY,
  GET_EXTERNAL_CMMS_FIELD_MAPPINGS_QUERY,
} from '../queries/cmms.query';
import { NotificationService } from './notification.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@vfi-ui/environments/environment';
import { downloadFile } from '@vfi-ui/util/helpers';

@Injectable({
  providedIn: 'root',
})
export class CmmsService {
  constructor(
    private readonly apollo: Apollo,
    private notificationService: NotificationService,
    private readonly http: HttpClient
  ) {}
  /**
   * Pulls the cmms fields
   *
   * @param {number} externalSystemId
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  pullCmmsFields(externalSystemId: number): Observable<boolean> {
    return this.apollo
      .mutate<{ getCmmsFields: boolean }>({
        fetchPolicy: 'network-only',
        mutation: PULL_EXTERNAL_CMMS_FIELDS_QUERY,
        variables: {
          externalSystemId,
        },
      })
      .pipe(
        map((d) => d.data.getCmmsFields),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field',
            'Succesfully initiated pulling of cmms fields'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_PULLING_CMMS_FIELD);
          return throwError(() => error);
        })
      );
  }
  /**
   * Gets cmms fields
   *
   * @returns {Observable<ExternalCmmsField[]>}
   * @memberof CmmsService
   */
  getCmmsFields({
    where,
    offset,
  }: {
    offset: number;
    where: ExternalCmmsFieldWhere;
  }): Observable<{
    externalCmmsFields: ExternalCmmsField[];
    externalCmmsFieldCount: number;
  }> {
    const options = {
      order: {
        field: ExternalCmmsFieldSortField.Name,
        direction: OrderDirection.ASC,
      },
      limit: 30,
      offset,
      where: { ...where, viewAll: true },
    };

    return this.apollo
      .query<{
        externalCmmsFields: ExternalCmmsField[];
        externalCmmsFieldCount: number;
      }>({
        fetchPolicy: 'network-only',
        query: EXTENRAL_CMMS_FIELDS_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data)
      );
  }

  /**
   * Gets the domain values
   *
   * @param {string} id
   * @returns {Observable<ExternalCmmsDomainValue[]>}
   * @memberof CmmsService
   */
  getCmmsDomainValues({
    where,
    offset,
  }: {
    where: ExternalCmmsDomainValueWhere;
    offset: number;
  }): Observable<{
    externalCmmsDomainValues: ExternalCmmsDomainValue[];
    externalCmmsDomainValueCount: number;
  }> {
    const options = {
      limit: 30,
      offset,
      order: {
        field: ExternalCmmsDomainValueSortField.Name,
        direction: OrderDirection.ASC,
      },
      where,
    };
    return this.apollo
      .query<{
        externalCmmsDomainValues: ExternalCmmsDomainValue[];
        externalCmmsDomainValueCount: number;
      }>({
        fetchPolicy: 'network-only',
        query: EXTENRAL_CMMS_DOMAIN_VALUE_QUERY,
        variables: {
          options,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data)
      );
  }
  /**
   * Creates the cmms field
   *
   * @param {CmmsExternalFieldCreateInput} input
   * @returns {Observable<ExternalCmmsField>}
   * @memberof CmmsService
   */
  createCmmsField(
    input: CmmsExternalFieldCreateInput
  ): Observable<ExternalCmmsField> {
    return this.apollo
      .mutate<{
        createExternalCmmsField: ExternalCmmsField;
      }>({
        fetchPolicy: 'network-only',
        mutation: CREATE_EXTERNAL_CMMS_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data.createExternalCmmsField),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field',
            'Succesfully created field'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_CREATE_CMMS_FIELD);
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes all values for the cmms field
   *
   * @param {String} cmmsFieldId
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  deleteCmmsFieldValues(cmmsFieldId: string): Observable<boolean> {
    return this.apollo
      .mutate<{ getCmmsDomain: boolean }>({
        fetchPolicy: 'network-only',
        mutation: DELETE_CMMS_FIELD_VALUES_MUTATION,
        variables: {
          cmmsFieldId,
        },
      })
      .pipe(
        map((d) => d.data.getCmmsDomain),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field',
            'All values deleted successfully'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_DELETE_CMMS_FIELD_VALUES);
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes all values for the cmms field
   *
   * @param {String} cmmsFieldId
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  deleteCmmsFieldTranslations(cmmsFieldId: string): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteExternalCmmsValueTranslations: boolean }>({
        fetchPolicy: 'network-only',
        mutation: DELETE_CMMS_FIELD_TRANSLATIONS_MUTATION,
        variables: {
          cmmsFieldId,
        },
      })
      .pipe(
        map((d) => d.data.deleteExternalCmmsValueTranslations),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field',
            'All translations deleted successfully'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_DELETE_CMMS_FIELD_TRANSLATIONS
          );
          return throwError(() => error);
        })
      );
  }

  /**
   * Getting cmms domain values
   *
   * @param {ExternalCmmsDomainValueCreateInput} input
   * @returns {Observable<ExternalCmmsDomainValue>}
   * @memberof CmmsService
   */
  createCmmsDomainValue(
    input: ExternalCmmsDomainValueCreateInput
  ): Observable<ExternalCmmsDomainValue> {
    return this.apollo
      .mutate<{
        createExternalCmmsDomainValue: ExternalCmmsDomainValue;
      }>({
        fetchPolicy: 'network-only',
        mutation: CREATE_CMMS_DOMAIN_VALUE_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data.createExternalCmmsDomainValue),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Domain Value',
            'Succesfully created domain value'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_CREATE_CMMS_DOMAIN_VALUE);
          return throwError(() => error);
        })
      );
  }
  /**
   * Updates a cmms field
   *
   * @param {string} id
   * @param {CmmsExternalFieldUpdateInput} input
   * @returns {Observable<ExternalCmmsField>}
   * @memberof CmmsService
   */
  updateCmmsField(
    id: string,
    input: CmmsExternalFieldUpdateInput
  ): Observable<ExternalCmmsField> {
    return this.apollo
      .mutate<{
        updateExternalCmmsField: ExternalCmmsField;
      }>({
        fetchPolicy: 'network-only',
        mutation: UPDATE_EXTERNAL_CMMS_QUERY,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data.updateExternalCmmsField),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field',
            'Succesfully updated field'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_CMMS_FIELD);
          return throwError(() => error);
        })
      );
  }
  /**
   * Updates a cmms domain value
   *
   * @param {string} id
   * @param {ExternalCmmsDomainValueUpdateInput} input
   * @returns {Observable<ExternalCmmsDomainValue>}
   * @memberof CmmsService
   */
  updateCmmsDomainValue(
    id: string,
    input: ExternalCmmsDomainValueUpdateInput
  ): Observable<ExternalCmmsDomainValue> {
    return this.apollo
      .mutate<{
        updateExternalCmmsDomainValue: ExternalCmmsDomainValue;
      }>({
        fetchPolicy: 'network-only',
        mutation: UPDATE_CMMS_DOMAIN_VALUE_QUERY,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data.updateExternalCmmsDomainValue),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Domain Value',
            'Succesfully updated domain value'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_CMMS_DOMAIN_VALUE);
          return throwError(() => error);
        })
      );
  }
  /**
   * Pulls cmms domcain values
   *
   * @param {string} cmmsFieldId
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  pullCmmsDomainValues(cmmsFieldId: string): Observable<boolean> {
    return this.apollo
      .mutate<{ getCmmsDomain: boolean }>({
        fetchPolicy: 'network-only',
        mutation: PULL_EXTERNAL_CMMS_DOMAIN_VALUE_QUERY,
        variables: {
          cmmsFieldId,
        },
      })
      .pipe(
        map((d) => d.data.getCmmsDomain),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Domain Value',
            'Succesfully initiated pulling of cmms domain values'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_PULLING_CMMS_DOMAIN_VALUE);
          return throwError(() => error);
        })
      );
  }
  /**
   * Gets cmms field mappings
   *
   * @param {string} cmmsFieldId
   * @returns {Observable<CmmsFieldMapping[]>}
   * @memberof CmmsService
   */
  getCmmsFieldMappings(cmmsFieldId: string): Observable<CmmsFieldMapping[]> {
    const input = { where: { externalFieldIds: [cmmsFieldId] } };
    return this.apollo
      .query<{
        externalCmmsFieldMappings: CmmsFieldMapping[];
      }>({
        fetchPolicy: 'network-only',
        query: EXTENRAL_CMMS_FIELD_MAPPIPNG_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data.externalCmmsFieldMappings)
      );
  }
  /**
   * Gets the cmms value translations
   *
   * @param {string} cmmsFieldId
   * @returns {Observable<CmmsValueTranslation[]>}
   * @memberof CmmsService
   */
  getCmmsValueTranslations(
    cmmsFieldId: string,
    offset = 0
  ): Observable<{
    externalCmmsValueTranslations: CmmsValueTranslation[];
    externalCmmsValueTranslationCount: number;
  }> {
    const input = {
      where: { externalFieldIds: [cmmsFieldId] },
      offset,
      order: {
        field: 'CREATED_AT',
        direction: OrderDirection.ASC,
      },
      limit: 30,
    };
    return this.apollo
      .query<{
        externalCmmsValueTranslations: CmmsValueTranslation[];
        externalCmmsValueTranslationCount: number;
      }>({
        fetchPolicy: 'network-only',
        query: EXTENRAL_CMMS_VALUE_TRANSLATIONS_QUERY,
        variables: { input },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d.data)
      );
  }

  /**
   * Create cmms field mappings
   *
   * @param {ExternalCmmsFieldMappingCreateInput} input
   * @returns {Observable<CmmsFieldMapping>}
   * @memberof CmmsService
   */
  createCmmsFieldMapping(
    input: ExternalCmmsFieldMappingCreateInput
  ): Observable<CmmsFieldMapping> {
    return this.apollo
      .mutate<{ createExternalCmmsFieldMapping: CmmsFieldMapping }>({
        fetchPolicy: 'network-only',
        mutation: CREATE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        map((d) => d.data.createExternalCmmsFieldMapping),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field Mapping',
            'Succesfully created cmms field mapping'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_CREATE_CMMS_FIELD_MAPPING);
          return throwError(() => error);
        })
      );
  }
  /**
   * Deletes a cmms field mappng
   *
   * @param {string} externalId
   * @param {string} internalId
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  deleteCmmsFieldMapping(
    externalId: string,
    internalId: string
  ): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteExternalCmmsFieldMapping: boolean }>({
        fetchPolicy: 'network-only',
        mutation: DELETE_EXTERNAL_CMMS_FIELD_MAPPING_QUERY,
        variables: {
          externalId,
          internalId,
        },
      })
      .pipe(
        map((d) => d.data.deleteExternalCmmsFieldMapping),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field Mapping',
            'Succesfully deleted cmms field mapping'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_DELETE_CMMS_FIELD_MAPPING);
          return throwError(() => error);
        })
      );
  }
  /**
   * Create a cmms value translation
   *
   * @param {ExternalCmmsValueTranslationCreateInput} input
   * @returns {Observable<CmmsValueTranslation>}
   * @memberof CmmsService
   */
  createCmmsValueTranslation(
    input: ExternalCmmsValueTranslationCreateInput
  ): Observable<CmmsValueTranslation> {
    return this.apollo
      .mutate<{
        createExternalCmmsValueTranslation: CmmsValueTranslation;
      }>({
        fetchPolicy: 'network-only',
        mutation: CREATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
        variables: {
          input,
        },
      })
      .pipe(
        map((d) => d.data.createExternalCmmsValueTranslation),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Value Translations',
            'Succesfully created cmms value translation'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_CREATE_CMMS_VALUE_TRANSLATION
          );
          return throwError(() => error);
        })
      );
  }
  /**
   * Update a cmms value translation
   *
   * @param {string} id
   * @param {ExternalCmmsValueTranslationUpdateInput} input
   * @returns {Observable<CmmsValueTranslation>}
   * @memberof CmmsService
   */
  updateCmmsValueTranslation(
    id: string,
    input: ExternalCmmsValueTranslationUpdateInput
  ): Observable<CmmsValueTranslation> {
    return this.apollo
      .mutate<{ updateExternalCmmsValueTranslation: CmmsValueTranslation }>({
        fetchPolicy: 'network-only',
        mutation: UPDATE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
        variables: {
          id,
          input,
        },
      })
      .pipe(
        map((d) => d.data.updateExternalCmmsValueTranslation),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Value Translations',
            'Succesfully updated cmms value translation'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_UPDATE_CMMS_VALUE_TRANSLATION
          );
          return throwError(() => error);
        })
      );
  }
  /**
   * Deletes a cmms value translation
   *
   * @param {string} id
   * @returns {Observable<boolean>}
   * @memberof CmmsService
   */
  deleteCmmsValueTranslation(id: string): Observable<boolean> {
    return this.apollo
      .mutate<{ deleteExternalCmmsValueTranslation: boolean }>({
        fetchPolicy: 'network-only',
        mutation: DELETE_EXTERNAL_CMMS_VALUE_TRANSLATION_QUERY,
        variables: {
          id,
        },
      })
      .pipe(
        map((d) => d.data.deleteExternalCmmsValueTranslation),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Value Translations',
            'Succesfully deleted cmms value translation'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(
            ERROR_DELETE_CMMS_VALUE_TRANSLATION
          );
          return throwError(() => error);
        })
      );
  }

  /**
   * fetch external cmms field mappings
   *
   * @returns {Observable<ExternalCmmsMappings[]>}
   * @memberof CmmsService
   */
  getExternalCmmsMappings(
    externalSystemId: number
  ): Observable<ExternalCmmsMappings[]> {
    return this.apollo
      .query<{ externalCmmsExternalFieldMaps: ExternalCmmsMappings[] }>({
        fetchPolicy: 'network-only',
        query: GET_EXTERNAL_CMMS_FIELD_MAPPINGS_QUERY,
        variables: { externalSystemId },
      })
      .pipe(
        filter((d) => !!d),
        map((d) => d?.data?.externalCmmsExternalFieldMaps)
      );
  }

  /**
   * Creates cmms field relation
   *
   * @param {string} externalId
   * @param {string} internalId
   * @return {*}  {Observable<boolean>}
   * @memberof CmmsService
   */
  createCmmsFieldRelation({
    childId,
    parentId,
  }: {
    childId: string;
    parentId: string;
  }): Observable<boolean> {
    return this.apollo
      .mutate<{ addRelatedParentExternalCmmsField: boolean }>({
        fetchPolicy: 'network-only',
        mutation: CREATE_EXTERNAL_CMMS_FIELD_RELATION_QUERY,
        variables: {
          childId,
          parentId,
        },
      })
      .pipe(
        map((d) => d.data.addRelatedParentExternalCmmsField),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field Relation',
            'Succesfully added cmms field relation'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_CREATE_CMMS_FIELD_RELATION);
          return throwError(() => error);
        })
      );
  }

  /**
   * Deletes cmms field relation
   *
   * @param {string} externalId
   * @param {string} internalId
   * @return {*}  {Observable<boolean>}
   * @memberof CmmsService
   */
  deleteCmmsFieldRelation({
    childId,
    parentId,
  }: {
    childId: string;
    parentId: string;
  }): Observable<boolean> {
    return this.apollo
      .mutate<{ removeRelatedParentExternalCmmsField: boolean }>({
        fetchPolicy: 'network-only',
        mutation: DELETE_EXTERNAL_CMMS_FIELD_RELATION_QUERY,
        variables: {
          childId,
          parentId,
        },
      })
      .pipe(
        map((d) => d.data.removeRelatedParentExternalCmmsField),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Field Relation',
            'Succesfully deleted cmms field relation'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_DELETE_CMMS_FIELD_RELATION);
          return throwError(() => error);
        })
      );
  }

  /**
   * Uploads a a csv file containinng the CMMS domain
   * values.
   *
   * @param {number} externalSystemId - The external system id
   * @param {File} cmmsFieldId - The field id
   * @param {File} file - The file containing the values to import
   * @return {Observable<SsoConfig>}  {Observable<SsoConfig>}
   * @memberof SsoService
   */
  importDomainValuesFile({
    externalSystemId,
    cmmsFieldId,
    file,
  }: {
    externalSystemId: number;
    cmmsFieldId: string;
    file: File;
  }): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<void>(
        `${environment.backend}/cmms/import-field-values/${externalSystemId}/${cmmsFieldId}`,
        formData
      )
      .pipe(
        tap(() =>
          this.notificationService.showSuccess(
            'Import Field Values',
            'Succesfully imported field values'
          )
        ),
        catchError((err) => {
          this.notificationService.showError(
            'Import Field Values',
            err?.error?.message
          );
          return throwError(() => err);
        })
      );
  }

  /**
   * Uploads a a csv file containinng the mapping
   * values.
   *
   * @param {number} externalSystemId - The external system id
   * @param {File} cmmsFieldId - The field id
   * @param {File} file - The file containing the values to import
   * @return {Observable<SsoConfig>}  {Observable<SsoConfig>}
   * @memberof SsoService
   */
  importMappingsFile({
    externalSystemId,
    cmmsFieldId,
    file,
  }: {
    externalSystemId: number;
    cmmsFieldId: string;
    file: File;
  }): Observable<void> {
    const formData = new FormData();
    formData.append('file', file);
    return this.http
      .post<void>(
        `${environment.backend}/cmms/import-mappings/${externalSystemId}/${cmmsFieldId}`,
        formData
      )
      .pipe(
        tap(() =>
          this.notificationService.showSuccess(
            'Import Mappings',
            'Succesfully imported mappings'
          )
        ),
        catchError((err) => {
          this.notificationService.showError(
            'Import Mappings',
            err?.error?.message
          );
          return throwError(() => err);
        })
      );
  }

  /**
   * Exports cmms field values
   *
   * @param {number} externalSystemId
   * @param {string} cmmsFieldId
   * @return {*}
   * @memberof CmmsService
   */
  exportCmmsFieldValues(externalSystemId: number, cmmsFieldId: string) {
    return this.http
      .get(
        environment.backend +
          `/cmms/export-field-values/${externalSystemId}/${cmmsFieldId}`,
        { responseType: 'arraybuffer', observe: 'response' }
      )
      .pipe(
        filter((d) => !!d),
        catchError((err) => {
          this.notificationService.showError(ERROR_DOWNLOAD_CSV);
          return throwError(err);
        }),
        tap((res) => {
          let fileName = '';
          try {
            fileName = res.headers
              .get('content-disposition')
              .replace('attachment; filename=', '');
          } catch {
            fileName = 'export-field-values.csv';
          }
          if (res.body?.byteLength) {
            const file = new Blob([res.body], {
              type: 'application/gzip',
            });
            downloadFile(file, fileName);
          }
        })
      );
  }

  /**
   * update cmms configuration
   *
   * @param {UpdateCmmsConfigInput} input
   * @returns
   * @memberof CmmsService
   */
  updateCmmsConfig(input: UpdateCmmsConfigInput) {
    return this.apollo
      .mutate({
        fetchPolicy: 'network-only',
        mutation: UPDATE_CMMS_CONFIG_QUERY,
        variables: {
          ...input,
        },
      })
      .pipe(
        filter((d) => !!d),
        tap(() =>
          this.notificationService.showSuccess(
            'Cmms Configuration',
            'Succesfully updated cmms configuration'
          )
        ),
        catchError((error) => {
          this.notificationService.showError(ERROR_UPDATE_CMMS_CONFIG);
          return throwError(() => error);
        })
      );
  }
}
