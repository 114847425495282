<div class="d-flex align-items-end">
  <div class="d-flex flex-column w-50" aria-label="notificationTime">
    <p class="fw-500 regular-14 dm40 mb-1">
      Notifications Enabled
      <i
        class="fas fa-info-circle ml-1 regular-12"
        [nzTooltipTitle]="timezoneTooltip"
        nzTooltipPlacement="top"
        nz-tooltip
      ></i>
      <ng-template #timezoneTooltip>
        <span
          >Times are based on the time zone of the facility ({{
            timezone()
          }})</span
        >
      </ng-template>
    </p>
    <div class="mb-2">
      <nz-radio-group
        [(ngModel)]="timeType"
        [ngModelOptions]="{ standalone: true }"
        (ngModelChange)="timeTypeChanged(timeType())"
      >
        <label nz-radio [nzValue]="notificationTimeType.Always">24/7</label>
        <label nz-radio [nzValue]="notificationTimeType.Schedule"
          >Limit to Schedule</label
        >
      </nz-radio-group>
    </div>
    <atom-vfi-multi-select
      label="Schedule"
      [value]="form()?.value?.dayOfWeek"
      placeholder="Select Days"
      [options]="weekdayOptions"
      [showSearch]="false"
      [readOnly]="timeType() === notificationTimeType.Always"
      [required]="!form()?.controls?.['dayOfWeek']?.valid"
      placement="bottom"
      (optionSelected)="valueChanged('dayOfWeek', $event)"
    ></atom-vfi-multi-select>
    <div class="d-flex align-items-center mt-2">
      <div class="d-flex flex-column mr-1 w-50">
        <span
          class="label"
          [class.iyellow-6]="
            timeType() === notificationTimeType.Schedule &&
            !timeRanges?.[0]?.['from']
          "
          >Start Time</span
        >
        <nz-time-picker
          aria-label="notificationFromTime"
          [ngModel]="timeRanges?.[0]?.['from']"
          (ngModelChange)="timeRangeChanged(0, 'from', $event)"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'Start Time'"
          [nzDisabled]="timeType() === notificationTimeType.Always"
        >
        </nz-time-picker>
      </div>
      <div class="d-flex flex-column w-50">
        <span
          class="label"
          [class.iyellow-6]="
            timeType() === notificationTimeType.Schedule &&
            !timeRanges?.[0]?.['to']
          "
          >End Time
        </span>
        <nz-time-picker
          aria-label="notificationToTime"
          [ngModel]="timeRanges?.[0]?.['to']"
          (ngModelChange)="timeRangeChanged(0, 'to', $event)"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'End Time'"
          [nzDisabled]="timeType() === notificationTimeType.Always"
        ></nz-time-picker>
      </div>
    </div>
    <div class="d-flex align-items-center mt-2" *ngIf="showTimeRange">
      <div class="d-flex flex-column mr-1 w-50">
        <span
          class="label"
          [class.iyellow-6]="
            timeType() === notificationTimeType.Schedule &&
            !timeRanges?.[1]?.['from']
          "
          >Start Time</span
        >
        <nz-time-picker
          aria-label="notificationFromTime"
          [ngModel]="timeRanges?.[1]?.['from']"
          (ngModelChange)="timeRangeChanged(1, 'from', $event)"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'Start Time'"
          [nzDisabled]="timeType() === notificationTimeType.Always"
        >
        </nz-time-picker>
      </div>
      <div class="d-flex flex-column w-50">
        <span
          class="label"
          [class.iyellow-6]="
            timeType() === notificationTimeType.Schedule &&
            !timeRanges?.[1]?.['to']
          "
          >End Time
        </span>
        <nz-time-picker
          aria-label="notificationToTime"
          [ngModel]="timeRanges?.[1]?.['to']"
          (ngModelChange)="timeRangeChanged(1, 'to', $event)"
          [nzUse12Hours]="true"
          nzFormat="h:mm a"
          [nzPlaceHolder]="'End Time'"
          [nzDisabled]="timeType() === notificationTimeType.Always"
        ></nz-time-picker>
      </div>
    </div>
  </div>
  <i
    *ngIf="
      timeType() === notificationTimeType.Schedule &&
      timeRanges?.[0]?.['to'] &&
      timeRanges?.[0]?.['from'] &&
      !showTimeRange
    "
    class="fa-solid fa-plus green-7 ml-2 mb-3 regular-14 cursor-pointer"
    (click)="addTimeRange()"
  ></i>
  <i
    *ngIf="showTimeRange"
    class="fa-solid fa-minus red-6 ml-2 mb-3 regular-14 cursor-pointer"
    (click)="removeTimeRange()"
  ></i>
</div>
<p class="error-text mb-0" *ngIf="showError" aria-label="notificationError">
  Please select a time span within the 24 hours of one day e.g. from 12:00 am
  until 11:59 pm
</p>
<div class="d-flex align-items-center mt-2 w-50">
  <atom-vfi-input
    label="Alarm Duration Before Notification Sent (minutes)"
    placeholder="Minutes Delay"
    [value]="form()?.value?.minutesDelay"
    type="number"
    (valueChanged)="valueChanged('minutesDelay', $event)"
  ></atom-vfi-input>
  <i
    class="fas fa-info-circle ml-2 mt-3 regular-12"
    [nzTooltipTitle]="nuisanceTooltip"
    nzTooltipPlacement="top"
    nz-tooltip
  ></i>
  <ng-template #nuisanceTooltip>
    <span
      >Alarms classified as nuisance over the past 7 days will only send
      notifications once per hour while active.</span
    >
  </ng-template>
</div>

<ng-template #customClearIcon>
  <i class="white far fa-times-circle"></i>
</ng-template>
